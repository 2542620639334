"use client";

import { reportError } from "@/common/application/debug";
import {
    AnalyticsEventData,
    AnalyticsTools,
} from "@/features/analytics/domain/entities/AnalyticsTools";

/**
 * Implementation for the tools that are used to report analytics events.
 */
export class GoogleAnalyticsTools extends AnalyticsTools {
    reportEvent({
        eventType,
        label,
        value,
        metadata,
    }: AnalyticsEventData): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (typeof window !== "undefined" && typeof gtag !== "undefined") {
            gtag("event", eventType, {
                event_label: label,
                event_value: value,
                ...metadata,
            });
        } else if (typeof gtag === "undefined") {
            reportError(
                "Google analytics is not available, or have been disabled by the user."
            );
        }

        return Promise.resolve();
    }

    async clientId(): Promise<string | false> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - This is a global variable that is set by the Google Analytics script.
        return window.__googleAnalyticsClientId || false;
    }

    async sessionId(): Promise<string | false> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - This is a global variable that is set by the Google Analytics script.
        return window.__googleAnalyticsSessionId || false;
    }
}
