"use client";

import accessAnalyticsStore from "@/features/analytics/application/analytics-store/analyticsStore";
import { AnalyticsIntegration } from "@/features/analytics/domain/entities/AnalyticsIntegration";
import { GoogleAnalyticsTools } from "@/features/analytics/integrations/google-analytics/GoogleAnalyticsTools";
import React, { Fragment } from "react";

interface GoogleAnalyticsIntegrationRegisterarProps {}

/**
 * Registers GA to the analytics within the app.
 * @constructor
 */
const GoogleAnalyticsIntegrationRegistrar: React.FC<
    GoogleAnalyticsIntegrationRegisterarProps
> = () => {
    const { setIntegration } = accessAnalyticsStore();

    setIntegration(
        AnalyticsIntegration.googleAnalytics,
        new GoogleAnalyticsTools()
    );

    return <Fragment />;
};

export default GoogleAnalyticsIntegrationRegistrar;
