"use client";

import {
    ViewLayerCategory,
    ViewLayerCategoryKeys,
} from "@/component-library/components/systems/view-layers/domain/ViewLayerCategory";
import { ViewLayerItem } from "@/component-library/components/systems/view-layers/domain/ViewLayerItem";
import { create } from "zustand";

interface ViewLayersStoreState {
    /**
     * Each view layer wraps views for a given category ([ViewLayerCategory]). The views for
     * each layer (i.e. views for a given category) are stored in an array with the category as
     * the key.
     */
    layers: {
        [key in keyof typeof ViewLayerCategory]: ViewLayerItem[];
    };
}

const initialState = (): ViewLayersStoreState => ({
    /**
     * On initialization, no views are on any layer. If we wanted to always include some views
     * regardless of the place where [ViewLayers] is used, we could add them here, but this
     * should be avoided; if you need to have common views for multiple places where
     * [ViewLayers] is used, create a wrapper component, which always adds the common views.
     * This way, the view layering system will remain generic, and usable for as many contexts
     * as possible.
     */
    layers: {
        overlay: [],
        notification: [],
        alert: [],
    },
});

interface ViewLayersStoreActions {
    /**
     * Pushes the given view(s) to the top of the layer.
     * @param category - Which kind of view is this? See [ViewLayerCategory].
     * @param layers to add to the top.
     */
    pushToLayer: (
        category: ViewLayerCategoryKeys,
        ...layers: ViewLayerItem[]
    ) => void;
    /**
     * Removes the view with the given id from the layer.
     * @param category
     * @param layerId
     */
    removeFromLayer: (category: ViewLayerCategoryKeys, layerId: string) => void;
}

export type ViewLayersStore = ViewLayersStoreState & ViewLayersStoreActions;

export const useViewLayers = create<ViewLayersStore>((set, get) => ({
    ...initialState(),
    pushToLayer: (
        category: ViewLayerCategoryKeys,
        ...layers: ViewLayerItem[]
    ) => {
        set({
            layers: {
                ...get().layers,
                [category]: [...get().layers[category], ...layers],
            },
        });
    },
    removeFromLayer: (category: ViewLayerCategoryKeys, layerId: string) => {
        set({
            layers: {
                ...get().layers,
                [category]: get().layers[category].filter(
                    ({ id }) => id !== layerId
                ),
            },
        });
    },
}));
