import { CSSProperties } from "react";
import { SpringValue, SpringValues } from "react-spring";

export type StyleXAcceptedStyleTypes =
    | CSSProperties
    | undefined
    | boolean
    | SpringValues
    | { [key in keyof CSSProperties]: SpringValue };

/**
 * Similar to [clsx()], but for styles. I.e. creates a single [CSSProperties] object from
 * optionally falsy (undefined/false) objects. Makes conditionally adding styles more streamlined.
 * @param styles
 */
export const stylex = (
    ...styles: StyleXAcceptedStyleTypes[]
): CSSProperties => {
    return (styles.filter((style) => !!style) as CSSProperties[]).reduce(
        (accumulator, currentStyle) => ({ ...accumulator, ...currentStyle }),
        {} as CSSProperties | SpringValues
    );
};
