import { AnalyticsEventType } from "@/features/analytics/domain/entities/AnalyticsEventType";
import { AnalyticsMetadata } from "@/features/analytics/domain/entities/AnalyticsMetadata";
import { md5 } from "hash-wasm";

/**
 * Data to provide when reporting events to the analytics.
 */
export interface AnalyticsEventData {
    eventType: AnalyticsEventType;
    label?: string;
    value?: number;
    metadata?: AnalyticsMetadata;
}

/**
 * Unified interface for all analytics tools.
 */
export abstract class AnalyticsTools {
    /**
     * Reports an analytics event.
     * @param eventData contains the data for the event
     */
    abstract reportEvent(eventData: AnalyticsEventData): Promise<void>;
    abstract clientId(): Promise<string | false>;
    abstract sessionId(): Promise<string | false>;
}

/**
 * Gives a way to anonymously report analytics events. By hashing
 * the user id, we can report analytics events without reporting the
 * user's identity.
 *
 * Note, uses md5 hashing, which is not secure for passwords, but
 * is sufficient for anonymizing user ids. And fast.
 */
export const anonymizeUserId = async (userId: string) => {
    // Lowercase, trim and hash the user id.
    const clean = userId.trim().toLowerCase();
    return md5(clean);
};
