"use client";

import { IntercomProvider } from "react-use-intercom";
import { FC, ReactNode } from "react";
import { ClientSideAccessiblePublicEnvValues } from "@/common/domain/constants/env/ClientSideAccessiblePublicEnvValues";

/* --------------------------------------------------
  Wraps the IntercomProvider of react-use-intercom as a client components
-------------------------------------------------- */
const IntercomClientProvider: FC<{ children: ReactNode }> = ({ children }) => {
    return ClientSideAccessiblePublicEnvValues.wezooIntercomAppId ? (
        <IntercomProvider
            appId={ClientSideAccessiblePublicEnvValues.wezooIntercomAppId}
            autoBoot={true}
        >
            {children}
        </IntercomProvider>
    ) : (
        children
    );
};

export default IntercomClientProvider;
