"use client";

import { reportInfo } from "@/common/application/debug";
import { hasValue } from "@/common/utilities/hasValue";
import supportedCurrencies from "@/component-library/components/currency/converted-currency/supportedCurrencies";
import timezoneToCurrency from "@/component-library/components/currency/converted-currency/timezoneToCurrency";
import { pricingFilterCurrency } from "@/features/filtering/filters/pricing-filter/pricing-filte-query-keys";
import { useQueryState } from "nuqs";
import { FC, useEffect } from "react";

export const UserCurrencyComponent: FC = () => {
    const [currency, setCurrency] = useQueryState(pricingFilterCurrency);

    useEffect(() => {
        if (!hasValue(currency)) {
            // Try figuring out the currency from the user's location.
            // If that fails, default to EUR.
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userCurrency = timezone
                ? timezoneToCurrency[timezone] ?? "EUR"
                : "EUR";
            const supportedCurrency = supportedCurrencies.includes(userCurrency)
                ? userCurrency
                : "EUR";

            reportInfo(
                "Currency not set, defaulting to",
                supportedCurrency,
                "based on timezone",
                timezone
            );

            void setCurrency(supportedCurrency);
        } else {
            reportInfo("Currency already set to", currency);
            const supportedCurrency = supportedCurrencies.includes(currency)
                ? currency
                : "EUR";
            if (supportedCurrency !== currency) {
                reportInfo(
                    `Currency ${currency} not supported, defaulting to ${supportedCurrency}`
                );
                void setCurrency(supportedCurrency);
            }
        }
    }, [currency, setCurrency]);
    return null;
};
