"use client";

import { ViewLayerItem } from "@/component-library/components/systems/view-layers/domain/ViewLayerItem";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";

interface UseRenderViewLayerProps {
    layerItems: ViewLayerItem[];
}

/**
 * Renders the components on the view layer.
 * @param layerItems - The views on a layer to render.
 */
export const useRenderViewLayer = ({ layerItems }: UseRenderViewLayerProps) => {
    const [renderedItemIds, setRenderedItemIds] = useState<string[]>([]);

    const [components, setComponents] = useState<ReactNode[]>([]);

    const itemIdsToRender = useMemo(
        () => layerItems.map(({ id }) => id),
        [layerItems]
    );

    useEffect(() => {
        /**
         * When there are no items to render, reset the state.
         */
        if (itemIdsToRender.length === 0) {
            setRenderedItemIds([]);
            setComponents([]);
        }
    }, [itemIdsToRender.length]);

    useEffect(() => {
        if (
            itemIdsToRender.reduce(
                (accumulator, id) =>
                    accumulator || !renderedItemIds.includes(id),
                false
            )
        ) {
            const newRenderedItemIds: string[] = [];
            setComponents(
                layerItems.map(({ id, component }) => {
                    newRenderedItemIds.push(id);
                    return <Fragment key={id}>{component}</Fragment>;
                })
            );

            setRenderedItemIds(newRenderedItemIds);
        }
    }, [itemIdsToRender, layerItems, renderedItemIds]);

    return components;
};
