import {
    loadingIndicatorDarkSvgStyle,
    loadingIndicatorLightSvgStyle,
    loadingIndicatorStyle,
} from "@/component-library/components/feedback/loading-indicator/LoadingIndicatorStyles.css";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React from "react";

interface LoadingIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
    lightIndicator?: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
    lightIndicator = false,
    className,
    ...restProps
}) => {
    return (
        <div className={clsx(loadingIndicatorStyle, className)} {...restProps}>
            <svg width={34} height={10}>
                <circle
                    r={4}
                    cx={5}
                    cy={5}
                    className={clsx(
                        lightIndicator
                            ? loadingIndicatorLightSvgStyle
                            : loadingIndicatorDarkSvgStyle
                    )}
                />
                <circle
                    r={4}
                    cx={15}
                    cy={5}
                    className={clsx(
                        lightIndicator
                            ? loadingIndicatorLightSvgStyle
                            : loadingIndicatorDarkSvgStyle
                    )}
                    style={assignInlineVars({
                        animationDelay: `0.15s`,
                    })}
                />
                <circle
                    r={4}
                    cx={25}
                    cy={5}
                    className={clsx(
                        lightIndicator
                            ? loadingIndicatorLightSvgStyle
                            : loadingIndicatorDarkSvgStyle
                    )}
                    style={assignInlineVars({
                        animationDelay: `0.3s`,
                    })}
                />
            </svg>
        </div>
    );
};

export default LoadingIndicator;
