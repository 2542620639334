"use client";

import Script from "next/script";
import React from "react";

interface GoogleAnalyticsComponentProps {
    gaMeasurementId: string;
    debug?: boolean;
}

/**
 * Injects Google Analytics to the page.
 * @param gaMeasurementId is the measurement id for Google Analytics
 * @param debug is whether to enable debug mode for Google Analytics
 * @constructor
 */
const GoogleAnalyticsComponent: React.FC<GoogleAnalyticsComponentProps> = ({
    gaMeasurementId,
    debug = false,
}) => {
    // Right now there is a bug in Next.js where [strategy="beforeInteractive"]
    // cannot be used in the app router. See
    // https://github.com/vercel/next.js/issues/49830
    return (
        <>
            {/* eslint-disable-next-line
             @next/next/no-before-interactive-script-outside-document */}
            <Script
                strategy="beforeInteractive"
                async={true}
                src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}
            />
            {/* eslint-disable-next-line
             @next/next/no-before-interactive-script-outside-document */}
            <Script
                strategy="afterInteractive"
                async={true}
                id="google-analytics"
            >
                {`
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', '${gaMeasurementId}'${debug ? `, { 'debug_mode':true }` : ``});
                          
                          gtag("consent", "default", {
                              ad_storage: "granted",
                              ad_user_data: "granted", 
                              ad_personalization: "granted",
                              analytics_storage: "granted",
                              functionality_storage: "granted",
                              personalization_storage: "granted",
                              security_storage: "granted",
                          });
                          gtag("set", "ads_data_redaction", true);
                          gtag("set", "url_passthrough", true);
                          
                          // Set the client id for Google Analytics.
                          gtag('get', '${gaMeasurementId}', 'client_id', (client_id) => {
                              window.__googleAnalyticsClientId = client_id;
                          });
                          
                          // Set the session id for Google Analytics.
                          gtag('get', '${gaMeasurementId}', 'session_id', (session_id) => {
                              window.__googleAnalyticsSessionId = session_id;
                          });
                    `}
            </Script>
        </>
    );
};

export default GoogleAnalyticsComponent;
