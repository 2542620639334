"use client";

import { useRenderViewLayer } from "@/component-library/components/systems/view-layers/application/useRenderViewLayer";
import { useViewLayers } from "@/component-library/components/systems/view-layers/application/useViewLayers";
import React from "react";

interface _NotificationsViewLayerProps {}

const _NotificationsViewLayer: React.FC<_NotificationsViewLayerProps> = () => {
    const items = useViewLayers((state) => state.layers.notification);

    return useRenderViewLayer({ layerItems: items });
};

export default _NotificationsViewLayer;
