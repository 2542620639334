import { ReactNode } from "react";
import { toast, TypeOptions } from "react-toastify";
import { createStore } from "zustand";

export interface AlertData {
    alertId: string;
    content: ReactNode;
    type: TypeOptions;
}

interface AlertStoreState {
    alerts: { [alertId: string]: AlertData };
}

const initialState = () => ({
    alerts: {},
});

interface AlertStoreActions {
    initialise: () => VoidFunction;
    notifyUser: (props: AlertData) => void;
}

type AlertStore = AlertStoreState & AlertStoreActions;

export const alertStore = createStore<AlertStore>((set, get) => ({
    ...initialState(),
    initialise: () => {
        const toastOnChangeHandler = toast.onChange((toastItem) => {
            set({
                alerts: Object.fromEntries(
                    Object.entries(get().alerts).filter(
                        ([alertId]) => alertId !== toastItem.id
                    )
                ),
            });
        });

        return () => {
            toastOnChangeHandler();
        };
    },
    notifyUser: (alertData) => {
        if (Object.keys(get().alerts).includes(alertData.alertId)) {
            toast.dismiss(alertData.alertId);
        }

        toast(alertData.content, {
            toastId: alertData.alertId,
            type: alertData.type,
            data: alertData,
        });

        set({
            alerts: { ...get().alerts, [alertData.alertId]: alertData },
        });
    },
}));
