import {
    buttonContentStyle,
    buttonStyle,
    buttonVariantStyle,
} from "@/component-library/components/buttons/button/ButtonStyles.css";
import LoadingIndicator from "@/component-library/components/feedback/loading-indicator/LoadingIndicator";
import { VariantProps } from "@/component-library/domain/entities/VariantProps";
import { theme } from "@/component-library/themes/theme.css";
import { stylex } from "@/component-library/utilities/stylex";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import Link from "next/link";
import React, {
    ElementType,
    FC,
    HTMLAttributes,
    ReactNode,
    useMemo,
} from "react";

export type AcceptedComponent = typeof Link | "button" | "a";

interface ButtonProps<TAcceptedComponent extends AcceptedComponent>
    extends VariantProps<
            | "CTA"
            | "primary"
            | "secondary"
            | "tertiary"
            | "light"
            | "outlined"
            | "smallOutlined"
            | "smallFilled"
            | "rounded"
            | "roundedFilled"
        >,
        Omit<HTMLAttributes<TAcceptedComponent>, "onCopy" | "onClick"> {
    children?: ReactNode;
    as?: TAcceptedComponent;
    type?: "submit" | "reset" | "button";
    isLoading?: boolean;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onClickDisabled?: (e: React.MouseEvent<HTMLElement>) => void;
    href?: string;
    target?: string;
    contentClassName?: string;

    inactiveColor?: string;
}

/**
 * Default button design.
 * @param children
 * @param variant - Primary is filled, secondary is outlined.
 * @param className
 * @param as - What component to render the button as, by default, rendered as a [button]-element.
 * @param type - When [as] is ["button"], defines the type of the button. Otherwise, ignored.
 * @param color - Color of the button.
 * @param style - Custom styles for the button.
 * @param loading - Disable the button and display a loading indicator
 * @param onClick
 * @param disabled
 * @param restProps - Other button element customisations.
 * @constructor
 */
const Button = <
    TAcceptedComponent extends AcceptedComponent,
    TComponent = ElementType<AcceptedComponent>,
    TReturnType extends ReactNode = ReturnType<FC<TComponent>>,
>({
    as = "button" as TAcceptedComponent,
    children,
    variant = "primary",
    className,
    contentClassName,
    type = "button",
    color,
    inactiveColor = theme.colors.surface.disabledSurface.o100,
    style,
    isLoading = false,
    onClick,
    onClickDisabled,
    disabled = false,
    href,
    target,
    ...restProps
    // eslint-disable-next-line sonarjs/cognitive-complexity
}: ButtonProps<TAcceptedComponent>): TReturnType => {
    const AsElement = as as ElementType;

    const interactive = useMemo(() => {
        return !(isLoading || disabled);
    }, [disabled, isLoading]);

    return (
        <AsElement
            className={clsx(
                buttonStyle,
                buttonVariantStyle({ variant }),
                className
            )}
            {...(as === "button" ? { type } : {})}
            style={stylex(
                assignInlineVars({
                    position: "relative",

                    backgroundColor: interactive
                        ? variant.toLowerCase().includes("filled") ||
                          variant === "CTA" ||
                          variant === "primary"
                            ? color
                            : "transparent"
                        : inactiveColor,

                    borderColor: ["outlined", "smalloutlined"].includes(variant)
                        ? color
                        : undefined,

                    color:
                        as === Link
                            ? ["outlined", "smalloutlined"].includes(variant)
                                ? color
                                : theme.colors.basic.white
                            : undefined,
                    textDecoration: "none",

                    cursor: isLoading
                        ? "progress"
                        : disabled && !onClickDisabled
                          ? "not-allowed"
                          : "pointer",
                }),
                style
            )}
            onClick={interactive ? onClick : onClickDisabled}
            disabled={!interactive && !onClickDisabled}
            href={href ?? ""}
            {...(interactive && href ? { target } : {})}
            {...restProps}
        >
            <div
                className={clsx(buttonContentStyle, contentClassName)}
                style={assignInlineVars({
                    visibility: !isLoading ? "visible" : "hidden",
                })}
            >
                {children}
            </div>
            {isLoading && (
                <LoadingIndicator
                    lightIndicator
                    style={assignInlineVars({
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    })}
                />
            )}
        </AsElement>
    ) as TReturnType;
};

export default Button;
