"use client";

import { useRenderViewLayer } from "@/component-library/components/systems/view-layers/application/useRenderViewLayer";
import { useViewLayers } from "@/component-library/components/systems/view-layers/application/useViewLayers";
import React from "react";

interface _AlertsViewLayerProps {}

const _AlertsViewLayer: React.FC<_AlertsViewLayerProps> = () => {
    const items = useViewLayers((state) => state.layers.alert);

    return useRenderViewLayer({ layerItems: items });
};

export default _AlertsViewLayer;
