import React, { Fragment, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

interface PortalProps {
    children: React.ReactNode;
    isShowing?: boolean;
}

/**
 * Renders content outside the main flow of the app. The content is placed
 * inside the body.
 * @param children - Contents of the Portal to render outside the app main flow.
 * @param isShowing indicates whether the portal is showing or unmounted from
 *   the DOM.
 * @constructor
 */
export const Portal: React.FC<PortalProps> = ({
    children,
    isShowing = true,
}) => {
    const [isMounted, setIsMounted] = useState(false);
    const [rootElement, setRootElement] = useState<HTMLBodyElement | null>(
        null
    );

    useIsomorphicLayoutEffect(() => {
        if (document === undefined) {
            return;
        }

        setRootElement(document.body as HTMLBodyElement);
    }, []);

    // Set [isMounted] to true on client when the component is mounted.
    useEffect(() => {
        setIsMounted(true);
    }, [setIsMounted, rootElement]);

    // Do not render the portal if it is not mounted to avoid SSR-errors.
    if (!isMounted) {
        return <></>;
    }

    return (
        <>
            {rootElement &&
                createPortal(
                    isShowing && (
                        <Fragment key="portal-fragment-key">
                            {children}
                        </Fragment>
                    ),
                    rootElement
                )}
        </>
    );
};
