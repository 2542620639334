"use client";

import { useRenderViewLayer } from "@/component-library/components/systems/view-layers/application/useRenderViewLayer";
import { useViewLayers } from "@/component-library/components/systems/view-layers/application/useViewLayers";
import React from "react";

interface _OverlaysViewLayerProps {}

const _OverlaysViewLayer: React.FC<_OverlaysViewLayerProps> = () => {
    const items = useViewLayers((state) => state.layers.overlay);

    return useRenderViewLayer({ layerItems: items });
};

export default _OverlaysViewLayer;
