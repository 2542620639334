"use client";

import Button from "@/component-library/components/buttons/button/Button";
import { Portal } from "@/component-library/components/layout/Portal";
import { alertStore } from "@/features/alerts/application/alertStore";
import {
    toastStyle,
    toastWrapperStyle,
} from "@/features/alerts/components/alert-styles.css";
import { clsx } from "clsx";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useStore } from "zustand";
import "react-toastify/dist/ReactToastify.css";

interface SetupAlertsProps {}

const SetupAlerts: React.FC<SetupAlertsProps> = () => {
    const { initialise } = useStore(alertStore);

    useEffect(() => {
        const remove = initialise();

        return () => remove();
    }, [initialise]);

    return (
        <Portal isShowing={true}>
            <ToastContainer
                className={clsx(toastWrapperStyle)}
                toastClassName={clsx(toastStyle)}
                position={"top-center"}
                icon={false}
                autoClose={20000}
                closeOnClick={false}
                closeButton={(closeProps) => (
                    <Button
                        variant="CTA"
                        onClick={(event) => {
                            closeProps.closeToast(event);
                        }}
                    >
                        Dismiss
                    </Button>
                )}
            />
        </Portal>
    );
};

export default SetupAlerts;
